var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "!!halal-connection$$",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://dev-api.halal-connections.stackfrost.com",
            FIREBASE_API_KEY: "AIzaSyDvgcHccBxWvvjwEH9qsyyMyIOJjbPUecM",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            storageBucket: "halal-connections.appspot.com",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b",
            measurementId: "G-0M562PX33Z"
        },
        DEVELOPMENT: {
            API_URL: "https://dev-api.halal-connections.stackfrost.com",
            FIREBASE_API_KEY: "AIzaSyDvgcHccBxWvvjwEH9qsyyMyIOJjbPUecM",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            storageBucket: "halal-connections.appspot.com",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b",
            measurementId: "G-0M562PX33Z"
        },
        STAGING: {
            API_URL: "https://dev-api.halal-connections.stackfrost.com",
            FIREBASE_API_KEY: "AIzaSyDvgcHccBxWvvjwEH9qsyyMyIOJjbPUecM",
            FIREBASE_AUTH_DOMAIN: "halal-connections.firebaseapp.com",
            FIREBASE_PROJECT_ID: "halal-connections",
            storageBucket: "halal-connections.appspot.com",
            FIREBASE_SENDER_ID: "335153950002",
            FIREBASE_APP_ID: "1:335153950002:web:0a37feae921a40e91b7b2b",
            measurementId: "G-0M562PX33Z"
        },
        PRODUCTION: {
            API_URL: "https://api.halalconnection.com"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;